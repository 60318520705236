<template>
  <div class="view pa24">
    <div class="d-flex align-items-center">
      <div class="mr20 mb10">
        <el-date-picker
            style="width: 100%"
            v-model="pickerValue"
            align="right"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          />
      </div>
      <div class="mr20 mb10">
        姓名：
        <el-input  class="w220 mr20" v-model="nickeName" placeholder="请输入员工姓名" />
        <el-button type="primary" @click="getTableData">搜索</el-button>
      </div>
      
    </div>
    <div>
      <!-- 佣金状态：
      <el-radio class="mr10" v-model="radio1" label="1" border
        >可发放佣金</el-radio
      >
      <el-radio style="margin-left:0" v-model="radio1" label="2" border>未入账佣金</el-radio> -->
      <!-- <el-button>搜索</el-button> -->
      <!-- <el-button type="primary">导出</el-button> -->
    </div>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total"
                  >
      <template v-slot:table>
        <el-table-column align="center" type="index" width="100px" label="头像">
          <template slot-scope="scope">
            <el-image style="width: 50px;height:50px" :src="scope.row.avatarUrl">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="姓名" />
        <el-table-column
          prop="phone"
          align="center"
          label="联系方式"
          show-overflow-tooltip
        />
        <el-table-column prop="position" align="center" label="部门" />
        <el-table-column prop="sumBalance" align="center" label="总佣金" />
        <el-table-column
          prop="balance"
          align="center"
          label="可提现佣金（元）"
        />
        <el-table-column
          prop="withdrawingBalance"
          align="center"
          label="提现中佣金（元）"
        />
        <el-table-column
          prop="withdrawBalance"
          align="center"
          label="已提现佣金"
        />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="detail(scope.row.distributorAppId,1)">详情</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { queryCommissionRecordUser } from "@/api/financing";
import { getDataTimeSec } from "@/utils";
export default {
  name: "employeeCommission",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      radio1: "1", //佣金状态
      tableData: [],
      nickeName:'',
      loading:'',
      total: 0, //总条数
      currentPage: 1, //当前页数
    };
    
  },
  watch:{
    pickerValue(val,old){
      console.log(val)
      if(val == null) {
       return this.searchCondition = ''
      }
      this.searchCondition = getDataTimeSec(val, 'yy-mm-dd') +" 00:00:00" 
    }
  },
  created(){
    this.getTableData()
  },
  methods:{
    //请求表格数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        distributorAppId: this.detallId,
        createTimes: this.searchCondition,
        nickeName:this.nickeName
      };
      queryCommissionRecordUser(data)
      .then((res) => {
        let arr = []
        this.loading = false;
        this.tableData = res.data.pageInfo.list
        this.total = res.data.pageInfo.total
        this.tableDataList = res.data.responseList || [];
        if(this.tableDataList){
          this.tableDataList.forEach(item =>{
            item.commissionRate = item.commissionRate+"%";
          });
        }
      })
      .catch((err) => {
        this.loading = false;
        this.tableData = [];
      });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.selectOrdersInfoPcAsync();
    },
    detail(id){
      this.$router.push({ path:`/personalCommissionDetall?id=${id}` });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>